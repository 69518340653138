import React from "react"
import PropTypes, { array } from "prop-types"
import { soundManager } from "soundmanager2"
class MusicPlayer2 extends React.Component {

  //orginial#  state = { song: null, paused: true, duration: 0, position: 0, percentage: 0, volume: 100, rawPosition: 0 };

  state = { song: true, paused: true, duration: 0, position: 0, percentage: 0, volume: 70, rawPosition: 0 };

  sound = null;
  duration = 0;
  position = 0;
  step = 10000;

  i = Math.ceil((Math.random() * 100) % 8 - 1);

  musicNames = [ "We20fifty - 80's Kids", "We20fifty - Blame It", "We20fifty - Computer Error",
                 "We20fifty - Desert Storm", "We20fifty - Four Guitars", "We20fifty - Old VHS Tape",
                 "We20fifty - Airplane Mode", "We20fifty - Babe", "We20fifty - Strange Love"
  ];

  musicUrls = [ "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+80s+Kids.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Blame+It.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Computer+Error+(Full).mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Desert+Storm+(Full).mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Four+Guitars+(Full).mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Old+VHS+Tape.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Airplane+Mode.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Babe.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we20fifty+-+Strange+Love.mp3"
  ];


  UNSAFE_componentWillMount() {
    let player = this;
    if(window.musicPlayer && window.musicPlayer.sound) {
      this.setState(window.musicPlayer.state);
      this.sound = window.musicPlayer.sound;
      if (this.sound) {
        this.sound.instanceOptions.whileplaying = function () {
          player.setDuration(this.duration);
          player.setPosition(this.position);
        }
      }
    } else {
      window.musicPlayer = {};
    }
    window.musicPlayer.play = (song) => {
      if(this.sound) {
        this.sound.destruct();
      }
      if(!song && this.state.song) {
        song = this.state.song;
      }
      if(song) {
        this.setState({song: song, paused: true});
        this.sound = soundManager.createSound({
          id: `song-${song.id}`,
          //url: song.tune_url,
          url: this.musicUrls[this.i],
          autoPlay: false,
          volume: this.state.volume,
          onload: function() {
              console.log('load', player.state.rawPosition);
              this.setPosition({ rawPosition: player.state.rawPosition });
          },
          whileplaying: function() {
            player.setDuration(this.duration);
            player.setPosition(this.position);
            // localStorage.musicPlayer = JSON.stringify(player.state);
          },
          onplay: () => {
            if (song.plays_url) {
              $.post(song.plays_url);
            }
          },
          onfinish: function() {
            this.i = (this.i + 1) % 5;
            // localStorage.removeItem("musicPlayer");
            player.setState({ song: true, paused: true, rawPosition: 0 });
            this.destruct();
          },
          ontimeout : function(status) {
            console.log('SM2 failed to start. Flash missing, blocked or security error?');
            console.log('The status is ' + status.success + ', the error type is ' + status.error.type);
          }
        });
      }
      // let savedState = JSON.parse(localStorage.getItem("musicPlayer"));
      // if(savedState) {
      //   this.setState(savedState);
      //   localStorage.removeItem("musicPlayer");
      // }
    }
  }

  componentWillUnmount() {
    window.musicPlayer.state = this.state;
    window.musicPlayer.sound = this.sound;
  }

  componentDidMount() {
    let player = this;
    soundManager.setup({
      url: '/assets/',
      debugMode: true,
      useHighPerformance: true,
      //useFastPolling: true,
      html5PollingInterval: 10,
      onready: function() {
        if(player.sound) {
          return;
        }
        window.musicPlayer.play();
      }
    });
  }

  togglePause = (event) => {
    event.preventDefault();
    if(this.state.song) {
      this.sound.togglePause();
      this.setState({ paused: !this.state.paused })
    }
  };

  fastForward = (event) => {
    event.preventDefault();
    if(this.state.song) {
      //this.i = (this.i + 1) % 5;//
      this.sound.setPosition(Math.min(this.position + this.step, this.duration));
    }
  };

  rewind = (event) => {
    event.preventDefault();
    if(this.state.song) {
      this.sound.setPosition(Math.max(this.position - this.step, 0));
      //if(this.i > 0) {
      //  this.i = (this.i - 1) % 5;
      //} else {
      //  this.i = 5
      //}
    }
  };

  setVolume = (event) => {
    if(this.sound) {
      this.sound.setVolume(event.target.value);
    }
    this.setState({ volume: event.target.value });
  };

  setDuration = (duration) => {
    this.duration = duration;
    this.setState({ duration: this.durationToTime(duration) });
  };

  setPosition = (position) => {
    this.position = position;
    let percentage = `${this.position*100/this.duration}%`;
    this.setState({ position: this.durationToTime(this.position), percentage: percentage, rawPosition: position });
  };

  scrub = (event) => {
    const position = event.clientX/event.target.offsetWidth*this.duration;
    if(this.sound) {
      console.log('position', position);
      this.sound.setPosition(position);
    }
  };

  durationToTime(duration) {
    let seconds = parseInt((duration/1000)%60);
    let minutes = parseInt((duration/(1000*60))%60);

    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  }

  songTitle = () => {
    if(this.state.song) {
      return [this.state.song.album_name, this.state.song.name].join(" - ")
      //return ["we20fifty - 80's Kids"]
    } else {
      return "";
    }
  };

  render() {
    let pauseButton = <a href="" onClick={this.togglePause} className="fas fa-pause" />;
    let playButton = <a href="" onClick={this.togglePause} className="fas fa-play" />;
    let songTitle = "";
    let songArt = "";
    let songDuration = "";
    let scrubbingArea = "";
    if(this.state.song) {
      //songTitle = [this.state.song.album_name, this.state.song.name].join(" - ");
      //[ "We20fifty", this.musicNames[this.i]].join(" - ")
      songTitle = <div className="song-name">{[ this.musicNames[this.i] ]}</div>;
      songArt = <img src={this.state.song.art_url} alt="" className="m-audiobar__song__cover" />;
      songDuration = <div className="m-audiobar__song__time">
        <div className="m-audiobar__song__time__current">{this.state.position}</div>
        <span className="m-audiobar__song__time__duration">{this.state.duration}</span>
      </div>;
      scrubbingArea = <span className="m-audiobar__scrub" onClick={this.scrub} />
    }
    return <div className="m-audiobar">
      <span className="m-audiobar__progress" style={{width: this.state.percentage }} />
      {scrubbingArea}
      <div className="m-audiobar__song">
        <div className="m-audiobar__song__wrap">
          {/*songArt*/}
          <div className="m-audiobar__song__info">
            <h4 className="m-audiobar__song__title">{songTitle}</h4>
            {songDuration}
          </div>
        </div>
      </div>
      <ul className="m-audiobar__controls">
        <li><a href="#" onClick={this.rewind} className="fas fa-backward" /></li>
        <li>{this.state.paused ? playButton : pauseButton}</li>
        <li><a href="#" onClick={this.fastForward} className="fas fa-forward" /></li>
      </ul>
      <div className="m-audiobar__offset">
        <div className="m-audiobar__offset__wrap">
          <form action="" className="m-form">
            <input type="range" onChange={this.setVolume} value={this.state.volume} min="0" max="100" />
          </form>
          <a href="#" className="icon--queue" className="m-audiobar__queue-trigger" />
        </div>
      </div>
    </div>
  }
}
export default MusicPlayer2
