import React from "react"
import PropTypes, { array } from "prop-types"
import { soundManager } from "soundmanager2"
class MusicPlayer extends React.Component {

  //orginial#  state = { song: null, paused: true, duration: 0, position: 0, percentage: 0, volume: 100, rawPosition: 0 };
  
  state = { song: true, paused: true, duration: '00:00', position: '00:00', percentage: 0, volume: 70, rawPosition: 0 , j: Math.abs(Math.ceil((Math.random() * 100)) % 15)  };

  sound = null;
  duration = 0;
  position = 0;
  step = 10000;

  musicContext = [ "TV Commercial", "TV Commercial", "TV Commercial", "Remix", "Custom Made", "Gaming",
                   "Custom Made", "TV Commercial", "Remix", "Remix", "Remix", "Custom Made", "TV Commercial",
                   "Custom Made", "Custom Made"
                  ]

  musicNames = [ "we2050 - Armed" ,"we2050 - Bad Robot", "we2050 - Cyber Runner","we2050 - Dark Space",
                 "we2050 - Death Star" , "we2050 - DiscoX", "we2050 - Facing a Dream", "we2050 - Feel Like This",
                 "we2050 - Fifteen", "we2050 - High on Ride", "we2050 - Neon Skies", "we2050 - Overseas",
                 "we2050 - Power Punch", "we2050 - Time Jumper", "we2050 - XCCV"
  ];

  musicUrls = [ "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Armed.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Bad+Robot.mp3", 
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Cyber+Runner.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Dark+Space.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Death+Star.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+DiscoX.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Facing+a+Dream.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Feel+Like+This.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Fifteen.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+High+On+Ride.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Neon+Skies.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Overseas.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Power+Punch.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+Time+Jumper.mp3",
                "https://we2050-prod.s3.us-east-2.amazonaws.com/songs/we2050+-+XCCV.mp3"
  ];

    UNSAFE_componentWillMount() {
    let player = this;
    if(window.musicPlayer && window.musicPlayer.sound) {
      this.setState(window.musicPlayer.state);
      this.sound = window.musicPlayer.sound;
      if (this.sound) {
        this.sound.instanceOptions.whileplaying = function () {
          player.setDuration(this.duration);
          player.setPosition(this.position);
        }
      }
    } else {
      window.musicPlayer = {};
    }
    window.musicPlayer.play = (song) => {
      if(this.sound) {
        this.sound.destruct();
      }
      if(!song && this.state.song) {
        song = this.state.song;
      }
      if(song) {
        this.setState({song: song, paused: true});
        this.sound = soundManager.createSound({
          url: this.musicUrls[this.state.j],
          autoLoad: false,
          autoPlay: false,
          volume: this.state.volume,
          onload: function() {
              console.log('load', player.state.rawPosition);
              this.setPosition({ rawPosition: player.state.rawPosition });
          },
          whileplaying: function() {
            player.setDuration(this.duration);
            player.setPosition(this.position);
            // localStorage.musicPlayer = JSON.stringify(player.state);
          },
          onplay: () => {
            if (song.plays_url) {
              $.post(song.plays_url);
            }
          },
          onfinish: function() {
            // localStorage.removeItem("musicPlayer");
            player.state.j ++ % 15
            player.setState({ song: true, paused: false, rawPosition: 0 });
            this.load({ url: player.musicUrls[player.state.j % 15] });
            this.play()
          },
          ontimeout : function(status) {
            console.log('SM2 failed to start. Flash missing, blocked or security error?');
            console.log('The status is ' + status.success + ', the error type is ' + status.error.type);
          }
        });
      }
      // let savedState = JSON.parse(localStorage.getItem("musicPlayer"));
      // if(savedState) {
      //   this.setState(savedState);
      //   localStorage.removeItem("musicPlayer");
      // }
    }
  }

  componentWillUnmount() {
    window.musicPlayer.state = this.state;
    window.musicPlayer.sound = this.sound;
  }

  componentDidMount() {
    let player = this;
    soundManager.setup({
      url: '/assets/',
      debugMode: true,
      useHighPerformance: true,
      //useFastPolling: true,
      html5PollingInterval: 10,
      onready: function() {
        if(player.sound) {
          return;
        }
        window.musicPlayer.play();
      }
    });
  }

  togglePause = (event) => {
    event.preventDefault();
    if(this.state.song) {
      this.sound.togglePause();
      this.setState({ paused: !this.state.paused })
    }
  };


  fastForward = (event) => {
    event.preventDefault();
    if(this.state.song) {
      this.state.j ++ % 15;
      this.sound.destruct();
      this.sound.load({ url: this.musicUrls[this.state.j%15] })
      this.state.paused = false
      this.sound.play()
    }
  }

  rewind = (event) => {
    event.preventDefault();
    if(parseInt(this.state.position) < 3 & this.state.j > 0) {
      this.state.j -- % 15;
      this.sound.destruct();
      this.sound.load({ url: this.musicUrls[this.state.j%15] })
      this.state.paused = false
      this.sound.play()
    }

    if(parseInt(this.state.position) < 3 & this.state.j == 0) {
      this.state.j = 14;
      this.sound.destruct();
      this.sound.load({ url: this.musicUrls[this.state.j%15] })
      this.state.paused = false
      this.sound.play()
    }
  }

  //fastForward = (event) => {
  //  event.preventDefault();
  //  if(this.state.song) {
      //this.i = (this.i + 1) % 5;//
  //    this.sound.setPosition(Math.min(this.position + this.step, this.duration));
  //  }
  //};

  //rewind = (event) => {
  //  event.preventDefault();
  //  if(this.state.song) {
  //    this.sound.setPosition(Math.max(this.position - this.step, 0));
  //    //if(this.i > 0) {
  //    //  this.i = (this.i - 1) % 5;
  //    //} else {
  //    //  this.i = 5
  //    //}
  //  }
  //};

  setVolume = (event) => {
    if(this.sound) {
      this.sound.setVolume(event.target.value);
    }
    this.setState({ volume: event.target.value });
  };

  setDuration = (duration) => {
    this.duration = duration;
    this.setState({ duration: this.durationToTime(duration) });
  };

  setPosition = (position) => {
    this.position = position;
    let percentage = `${this.position*100/this.duration}%`;
    this.setState({ position: this.durationToTime(this.position), percentage: percentage, rawPosition: position });
  };

  scrub = (event) => {
    const position = event.clientX/event.target.offsetWidth*this.duration;
    if(this.sound) {
      console.log('position', position);
      this.sound.setPosition(position);
    }
  };

  durationToTime(duration) {
    let seconds = parseInt((duration/1000)%60);
    let minutes = parseInt((duration/(1000*60))%60);

    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
  }

  songTitle = () => {
    if(this.state.song) {
      return [this.state.song.album_name, this.state.song.name].join(" - ")
      //return ["we20fifty - 80's Kids"]
    } else {
      return "";
    }
  };

/*
  handleKeyDown = (event) => {
    if(event.keyCode == 32){
      this.setState({ paused: !this.state.paused })
    }
  }
*/

  render() {
    let pauseButton = <a href="" onClick={this.togglePause} className="fas fa-pause" />;
    let playButton = <a href="" onClick={this.togglePause} className="fas fa-play" />;
    let songTitle = "";
    let songContext = "";
    let songDuration = "";
    let scrubbingArea = "";
    if(this.state.song) {
      songTitle = <div className="song-name">{[ this.musicNames[this.state.j%15] ]}</div>;
      songContext = <div className="song-context">{[ this.musicContext[this.state.j%15] ]}</div>
      songDuration = <div className="m-audiobar__song__time">
        <div className="m-audiobar__song__time__current">{this.state.position}</div>
        <span className="m-audiobar__song__time__duration">{this.state.duration}</span>
      </div>;
      scrubbingArea = <span className="m-audiobar__scrub" onClick={this.scrub} />
    }
    return <div className="m-audiobar">
      <span className="m-audiobar__progress" style={{width: this.state.percentage }} />
      {scrubbingArea}
      <div className="m-audiobar__song">
        <div className="m-audiobar__song__wrap">
          {/*songArt*/}
          <div className="m-audiobar__song__info">
            <h4 className="m-audiobar__song__title">{songTitle}</h4>
            <div className="m-audiobar__song__context">{songContext}</div>
            {songDuration}
          </div>
        </div>
      </div>
      <ul className="m-audiobar__controls">
        <li><a href="#" onClick={this.rewind} className="fas fa-backward" /></li>
        <li>{this.state.paused ? playButton : pauseButton}</li>
        <li><a href="#" onClick={this.fastForward} className="fas fa-forward" /></li>
      </ul>
      <div className="m-audiobar__offset">
          <form action="" className="m-form">
            <input type="range" onChange={this.setVolume} value={this.state.volume} min="0" max="100" />
          </form>
          <a href="#" className="icon--queue" className="m-audiobar__queue-trigger" />
      </div>
    </div>
  }
}
export default MusicPlayer
