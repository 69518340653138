// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import LocomotiveScroll from 'locomotive-scroll';

//const scroll = new LocomotiveScroll({
//    el: document.querySelector('[data-scroll-container]'),
//    smooth: true
//});

document.addEventListener('turbolinks:load', function(event) {
    if (typeof gtag === 'function') {
      gtag('config', 'G-M3JS3W303Z', {
        'page_location': event.data.url
      })
    }
  })

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("parallax-js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require es5-shim
//= require react
//= require react_ujs
//= require soundmanager2
//= require components
//= require is_touch
//= require clipboard
//= require cookies_eu
//= require locomotivescroll
//= require parallax-js

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.jQuery = $;
window.$ = $;